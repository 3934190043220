// Basic CSS Reset
// @font-face {
//   font-family: 'CustomFont';
//   src: url('../fonts/YoungSerif-Regular.ttf') format('ttf');
// }

html,
body,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
form,
fieldset,
legend,
button,
input,
textarea,
div {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font-weight: inherit;
  vertical-align: baseline;
  box-sizing: border-box;
  // scroll-behavior: smooth;
  // font-family: 'CustomFont';
}

// Apply box-sizing to everything
* {
  box-sizing: border-box;
}

// Set a baseline font size and font family
body {
  // font-family: 'CustomFont';
  font-size: 16px;
  line-height: 1.5;
}

// Additional reset or normalization styles can be added here
