@import "../../assets/styles/styles.scss";

.popup {
  $a: &;
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  top: 0;

  &-ovr {
    background-color: rgba($black, 0.65);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 4;
    overflow-x: auto;
  }

  &-content {
    background-color: $white;
    border-radius: 40px;
    width: 100%;
    max-width: 1280px;
    min-height: 100vh;
    position: relative;
    margin: 0 auto;
    margin-top: 80px;
    margin-bottom: 80px;
    padding: 40px;
  }

  @media screen and (min-width: 1px) and (max-width: 755px) {
    #{$a}-content {
      border-radius: 40px 40px 0 0;
      margin-bottom: 0;
    }
  }

  @media screen and (min-width: 1px) and (max-width: 460px) {
    #{$a}-content {
      margin-top: 40px;
      padding: 40px 24px;
      padding-bottom: 0;
      padding-top: 36px;
    }
  }

}
