@import "../../assets/styles/styles.scss";

/* RadioButton.css */
.radio-button {
  $rb: &;

  display: flex;
  align-items: center;
  margin-bottom: 10px;
  cursor: pointer;
  position: relative;

  font-size: 18px;
  line-height: 40px;

  @media screen and (min-width: 1px) and (max-width: 460px) {
    padding-left: 36px;
    #{$rb}__radio {
      left: 0;
      top: 8px;
      position: absolute;
    }
  }

  &__input {
    &-txt {
      &-wrapper {
        float: right;
        padding-left: 12px;

        &:before {
          // content: "";
          position: absolute;
          bottom: -16px;
          right: 0;
          bottom: -5px;
          width: 1px;
          background: $black;
          height: 6px
        }

        &.focus {
          input {
            &.radio-button__input-txt {
              border-bottom: 1px solid $orange;
            }
          }
          &:before {
            background: $orange;
          }
        }
      }

    }
  }

  input {
    &.radio-button__input {
      &-txt {
        display: inline-block;
        border-bottom: 1px solid $black;
        background-color: transparent;
        outline: none;
        font-size: 16px;
        line-height: 24px;
        padding-bottom: 0;
        height: 24px;
      }
    }
  }


  &__radio {
    width: 24px;
    height: 24px;
    line-height: 24px;
    position: relative;
    top: 5px;
    margin-right: 8px;
    cursor: pointer;
    border: 1px solid #666;

    /* Style for the label */
    &:before {
      content: '';
      display: block;
      width: 14px;
      height: 8px;
      background-color: transparent;
      opacity: 0;
      position: absolute;
      transform: rotate(-45deg);
      left: 3px;
      top: 3px;
      border-left: 2px solid $black;
      border-bottom: 2px solid $black;
      cursor: pointer;
    }

    &:after {
      content: '';

    }

    /* Style for the selected state */
    &:checked {
      background-color: transparent;
      &:before {
        opacity: 1;
      }
    }
  }

  @media screen and (min-width: 1px) and (max-width: 460px) {
    #{$rb}__radio {
      font-size: 16px;
    }
  }

}



