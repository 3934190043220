@import "../../../assets/styles/styles.scss";

section {
  background-color: $seashell;

  @keyframes zoom {
    0% {
      transform: scale(1.2);
    }
    50% {
      transform: scale(1.5); /* Adjust the scale factor for zoom level */
    }
    100% {
      transform: scale(1.2);
    }
  }

  &.opener {
    position: fixed;
    z-index: 2;
    height: 100vh;
    width: 100%;
    overflow: hidden;
    transition: height 0.75s cubic-bezier(0.25, 0.1, 0.25, 1), opacity 0.3s ease;

    .img-bg {
      position: absolute;
      width: 100%;
      height: 100vh;
      background-image: url('../../../../public/images/bg.png');
      background-size: cover;
      background-position: left;
      animation: zoom 50s infinite alternate;
      filter: brightness(67%);
    }

    &.hide {
      transition-delay: 350ms;
      height: 0vh;

      .img-bg {
        height: 0vh;
      }

      .v-center {
        opacity: 0;
      }
    }
  }

  &.closer {
    position: relative;
    overflow: hidden;
    transition: height 0.75s cubic-bezier(0.25, 0.1, 0.25, 1), opacity 0.3s ease;

    .img-bg {
      position: absolute;
      width: 100%;
      height: 0vh;
      background-image: url('../../../../public/images/bg.png');
      background-size: cover;
      background-position: left;
      animation: zoom 50s infinite alternate;
      filter: brightness(67%);
    }

    &.finished {
      transition-delay: 350ms;
      height: 0vh;

      .v-center {
        opacity: 0;
      }

      &.show {
        height: 100vh;

        .img-bg {
          height: 100vh;
        }

        .v-center {
          opacity: 1;
        }
      }
    }
  }

  &.loader {
    background-color: $tuna;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    overflow: hidden;
    transition: height 0.75s cubic-bezier(0.25, 0.1, 0.25, 1), opacity 0.3s ease;
    z-index: 3;

    img {
      opacity: 1;
      transition: opacity cubic-bezier(0.25, 0.1, 0.25, 1) .3s;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &.hide {
      transition-delay: 350ms;
      height: 0;

      img {
        opacity: 0;
      }
    }
  }
}

.transitioner {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 100%;
  z-index: 10;
  background-color: $tuna;
  transition: all .5s ease;

  &.active {
    bottom: 0;
  }
}

.section-divider {
  .v-center {
    opacity: 1;
    transition: opacity cubic-bezier(0.25, 0.1, 0.25, 1) .3s;

    &.has-cover {
      background: #2e323d;
      color: #fff;
      padding: 55px;
      border-bottom: 4px solid $orange;
      position: relative;
      // border-radius: 24px;
      z-index: 2;

      .a-h1, a-p {
        color: $white;
        // font-family: Roboto, sans-serif;
      }
    }
  }
}

// @media screen and (min-width: 1px) and (max-width: 460px) {
// }

@media screen and (min-width: 1px) and (max-width: 755px) {
  .slider {
    &-content {
      .a-h2 {
        padding: 0 90px;
      }
    }
  }
}

@media screen and (min-width: 1px) and (max-width: 512px) {
  .slider {
    &-content {
      .a-h2 {
        padding: 0 12px;
      }
    }
  }
  .section-divider {
    .v-center {
      &.has-cover {
        padding: 24px;
      }
    }
  }
}

.slider {
  &-wrapper {
    display: none;

    &.active {
      display: block;
    }
  }
}



.title-section {
  color: $white;
  font-size: 24px;
  line-height: 40px;
  position: absolute;
  left: 0;
  letter-spacing: 1.1px;
}

.btn-wrapper {
  &.floating {
    background-color: rgba($tuna, 1);
    padding: 16px 12px;
    position: fixed;
    left: 0;
    right: 0;
    // transition
    opacity: 0;
    transition: all 0.45s cubic-bezier(0.25, 0.1, 0.25, 1);
    bottom: -90px;

    &.show {
      opacity: 1;
      bottom: 0;
    }

    &-top {
      bottom: auto;
      top: -70px;
      height: 56px;
      z-index: 2;
      padding: 8px 12px;
      background: #f1f1f1;
      border-bottom: 1px solid #999;
      text-align: center;

      .title-section {
        color: $black;
        font-size: 16px;
        position: relative;
        text-align: center;
      }

      &.show {
        bottom: auto;
        top: 0;
      }
    }
  }
  // .progress {

  // }
}

@media screen and (min-width: 1px) and (max-width: 755px) {
  .btn-wrapper {
    &.floating {
      padding-left: 0;
      padding-right: 0;

      .kembali {
        float: left;
        position: relative;
        left: -32px;
      }
    }
  }
}

@media screen and (min-width: 1px) and (max-width: 460px) {
  .btn-wrapper {
    &.floating {
      padding-left: 0;
      padding-right: 0;

      .kembali {
        float: left;
        padding-left: 12px;
      }
    }
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: $tuna;
}
