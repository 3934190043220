.mt {
  &-8 {
    margin-top: 8px;
  }
  &-16 {
    margin-top: 16px;
  }
  &-24 {
    margin-top: 24px;
  }
}

.ml {
  &-8 {
    margin-left: 8px;
  }
  &-16 {
    margin-left: 16px;
  }
  &-24 {
    margin-left: 24px;
  }
}

.mb {
  &-8 {
    margin-bottom: 8px;
  }
  &-16 {
    margin-bottom: 16px;
  }
  &-24 {
    margin-bottom: 24px;
  }
  &-32 {
    margin-bottom: 32px;
  }
  &-40 {
    margin-bottom: 40px;
  }
  &-48 {
    margin-bottom: 48px;
  }
  &-64 {
    margin-bottom: 64px;
  }
}

.mr {
  &-8 {
    margin-right: 8px;
  }
  &-16 {
    margin-right: 16px;
  }
  &-24 {
    margin-right: 24px;
  }
}

.pt {
  &-8 {
    padding-top: 8px;
  }
  &-16 {
    padding-top: 16px;
  }
  &-24 {
    padding-top: 24px;
  }
  &-80 {
    padding-top: 80px;
  }
}

.pl {
  &-8 {
    padding-left: 8px;
  }
  &-16 {
    padding-left: 16px;
  }
  &-24 {
    padding-left: 24px;
  }
}

.pb {
  &-8 {
    padding-bottom: 8px;
  }
  &-16 {
    padding-bottom: 16px;
  }
  &-24 {
    padding-bottom: 24px;
  }
  &-80 {
    padding-bottom: 80px;
  }
  &-120 {
    padding-bottom: 120px;
  }
}

.pr {
  &-8 {
    padding-right: 8px;
  }
  &-16 {
    padding-right: 16px;
  }
  &-24 {
    padding-right: 24px;
  }
}

@media screen and (min-width: 1px) and (max-width: 460px) {
  .mt-m {
    &-8 {
      margin-top: 8px;
    }
    &-16 {
      margin-top: 16px;
    }
    &-24 {
      margin-top: 24px;
    }
  }

  .ml-m {
    &-8 {
      margin-left: 8px;
    }
    &-16 {
      margin-left: 16px;
    }
    &-24 {
      margin-left: 24px;
    }
  }

  .mb-m {
    &-8 {
      margin-bottom: 8px;
    }
    &-16 {
      margin-bottom: 16px;
    }
    &-24 {
      margin-bottom: 24px;
    }
    &-32 {
      margin-bottom: 32px;
    }
    &-40 {
      margin-bottom: 40px;
    }
    &-48 {
      margin-bottom: 48px;
    }
    &-64 {
      margin-bottom: 64px;
    }
  }

  .mr-m {
    &-8 {
      margin-right: 8px;
    }
    &-16 {
      margin-right: 16px;
    }
    &-24 {
      margin-right: 24px;
    }
  }

  .pt-m {
    &-8 {
      padding-top: 8px;
    }
    &-16 {
      padding-top: 16px;
    }
    &-24 {
      padding-top: 24px;
    }
    &-48 {
      padding-top: 48px;
    }
    &-80 {
      padding-top: 80px;
    }
    &-96 {
      padding-top: 96px;
    }
  }

  .pl-m {
    &-8 {
      padding-left: 8px;
    }
    &-16 {
      padding-left: 16px;
    }
    &-24 {
      padding-left: 24px;
    }
  }

  .pb-m {
    &-8 {
      padding-bottom: 8px;
    }
    &-16 {
      padding-bottom: 16px;
    }
    &-24 {
      padding-bottom: 24px;
    }
    &-80 {
      padding-bottom: 80px;
    }
    &-96 {
      padding-bottom: 96px;
    }
    &-120 {
      padding-bottom: 120px;
    }
  }

  .pr-m {
    &-8 {
      padding-right: 8px;
    }
    &-16 {
      padding-right: 16px;
    }
    &-24 {
      padding-right: 24px;
    }
  }
}




