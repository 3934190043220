@import "../../assets/styles/styles.scss";

.img-gal {
  $i: &;

  &-ovr {
    &-grid {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
      gap: 16px;
      margin-top: 32px;
      text-align: center;

      @media screen and (min-width: 1px) and (max-width: 460px) {
        gap: 0;
        grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
      }
    }

    &-item {
      break-inside: avoid;
      margin-bottom: 24px;
      // max-height: 350px;
      overflow: hidden;
      position: relative;

      &::before, &::after {
        position: absolute;
        content: '';
      }

      &::before {
        border-radius: 40px;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-color: rgba(0,0,0,0.4);
      }

      img {
        border-radius: 40px;
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
  }


}
