@import "colors";

// dark theme
.dark-theme {

  // adjust text color
  body, h1, h2, h3, p, a {
    color: $white; // Apply to links
  }
}
