@import
  "~tachyons/css/tachyons",
  "normalize",
  "colors",
  "spacing",
  "typography",
  "theme",
  "base";

  @import url('https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400;0,700;1,400;1,600;1,700&family=Roboto+Slab:wght@100;700;800&family=Roboto:wght@100;300;400;700;900&display=swap');

  body {
    // font-family: "Sofia", sans-serif;
    // font-family: "Audiowide", sans-serif;
    // font-family: 'Playfair', Tofu;
    // font-family: 'Roboto Slab', serif;
    font-family: 'Lora', serif;

  }
