@import "../../assets/styles/styles.scss";

.button {
  background-color: $palliser;
  color: white;
  border: none;
  border-radius: 24px;
  padding: 10px 32px;
  cursor: pointer;
  font-size: 16px;
  transition: all .3s ease;

  &:hover {
    background-color: #0056b3;

    .arrow {
      &-left {
        background-color: rgba($black, 0.6);

        &::before {
          border-color: rgba($black, 0.6);
          border-bottom-color: transparent;
          border-right-color: transparent;
        }
      }
    }
  }

  &.disabled {
    opacity: .5;
    cursor: default;
  }

  &.primary {
    background-color: $palliser;
    color: white;
    border: 1px solid $palliser;

    &:hover {
      background-color: rgba($palliser, 0.8);
    }
  }

  &.secondary {
    background-color: transparent;
    color: $white;
    border: 1px solid transparent;

    &:hover {
      background-color: transparent;
    }
  }

  &.white {
    background-color: white;
    color: #333;
    border: 1px solid #ccc;

    &-transparent {
      background-color: transparent;
      color: $white;
      border: 1px solid $white;

      &:hover {
        background: $white;
        color: #333;
        border: 1px solid #ccc;
      }
    }
  }

  &.black {
    background-color: white;
    color: #333;
    border: 1px solid #ccc;

    &-transparent {
      background-color: transparent;
      color: $black;
      border: 1px solid $black;

      &:hover {
        background: transparent;
        color: rgba($black, 0.6);
        border: 1px solid rgba($black, 0.6);
      }
    }
  }

  &.link {
    border: 1px solid transparent;
    background-color: transparent;
    color: black;

    &-white {
      color: white;
    }

    &:hover {
      border: 1px solid transparent;
      background-color: transparent;
    }
  }

  .arrow {
    &-left {
      position: relative;
      width: 20px;
      height: 1px;
      background-color: #000000;
      display: inline-block;
      top: -5px;
      margin-right: 8px;
      transition: all .3s ease;

      &:before {
        content: '';
        position: absolute;
        width: 6px;
        height: 6px;
        border: 1px solid #000000;
        border-bottom-color: transparent;
        border-right-color: transparent;
        transform: rotate(-45deg);
        left: 0px;
        top: -3.5px;
        transition: all .3s ease;
      }
    }
  }

  @media screen and (min-width: 1px) and (max-width: 460px) {

  }

}
