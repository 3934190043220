@import "../../assets/styles/styles.scss";

.input-group {
  position: relative;

  &__label {
    text-transform: uppercase;
    font-size: 14px;
    color: #999999;
    text-transform: uppercase;
  }

  @media screen and (min-width: 1px) and (max-width: 460px) {
    padding-top: 12px;
    margin-bottom: 24px;
  }
}
