$white: #ffffff;
$black: #000000;

$grey1: #999999;
$orange: #b87845;
$dawn: #d8d8d8;
$tuna: #343841;
$seashell: #f1f1f1;
$palliser: #986c49;

$red: #964a0bd1;
$green1: #d0d738a6;
$green2: #93982459;
$green3: #5b5f0259;

