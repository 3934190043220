@import "../../assets/styles/styles.scss";

.input-form {
  $if: &;

  padding-top: 40px;
  margin-bottom: 40px;
  position: relative;
  border-bottom: 2px solid $dawn;

  &.txt-white {
    #{$if}__input {
      color: $white;
    }
  }

  &:before {
    content: "";
    position: absolute;
    bottom: -16px;
    right: 0;
    width: 2px;
    background: $dawn;
    height: 14px
  }

  &.focus {
    border-color: $orange;

    #{$if}__label {
      top: 0;
      color: $orange;
    }

    #{$if}__trigger {
      display: none;
    }

    &:before {
      background: $orange;
    }
  }

  &.text-top {
    padding-top: 0;
    #{$if}__label {
      display: block;
      position: relative;
      top: 0;
      margin-bottom: 12px;
    }
  }

  &__trigger {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 40px;
    cursor: pointer;
  }

  &__label {
    text-transform: uppercase;
    font-size: 14px;
    color: $grey1;
    position: absolute;
    top: 55px;
    transition: top .35s cubic-bezier(0.25, 0.1, 0.25, 1);
  }

  &__input {
    background-color: transparent;
    -webkit-tap-highlight-color: transparent;
    outline: transparent;

    &::placeholder {
      font-size: 16px;
      font-style: italic;
    }
  }

  &__error-warning {
    position: absolute;
    bottom: -28px;
    left: 0;
    display: none;
    color: $red;

    &.show {
      display: block;
    }
  }

  @media screen and (min-width: 1px) and (max-width: 460px) {
    padding-top: 24px;

    #{$if}__label {
      top: 36px;
    }

    #{$if}__error-warning {
      font-size: 14px;
    }

    #{$if}__input {
      font-size: 18px;
      padding-bottom: 0;
    }

  }
}



