.container-refresh {
  text-align: center;
  padding: 40px 0;

  h1 {
    font-size: 36px;
    margin-bottom: 24px;
  }

  .link {
    display: inline-block;
    padding: 12px 24px;
    border: 1px solid #000;
    border-radius: 24px;
    margin: 0 12px;
  }
}
