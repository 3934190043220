.container {
  max-width: 1320px;
  margin: 0 auto;
  padding-left: 12px;
  padding-right: 12px;
}

// row
.row {
  margin-left: -12px;
  margin-right: -12px;

  &:after {
    content: '';
    clear: both;
    display: block;
  }
}

// column
.col {
  padding-left: 12px;
  padding-right: 12px;

  &-d-half {
    float: left;
    width: 50%;
  }
  &-d-full {
    width: 100%;
  }

  &-d-60 {
    float: left;
    width: 60%;
  }

  &-d-70 {
    float: left;
    width: 70%;
  }

  &-d-80 {
    float: left;
    width: 80%;
  }
}


// input
input, select, textarea {
  font-size: 16px;
  border: none;
  border: 1px solid $dawn;
  padding: 10px;
  margin: 0;
  color: #666;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  // font-family: Roboto,sans-serif;
}

input[type=email], input[type=number], input[type=password], input[type=text], select, textarea {
  font-size: 30px;
  color: #000;
  border: none;
  padding: 0 0 15px;
  height: 40px;
  width: 100%;
  // font-family: 'CustomFont';
}

// display
.db {
  display: block;
}
.dib {
  display: inline-block;
}
.di {
  display: inline;
}
.dn {
  display: none;
}

@media screen and (min-width: 1px) and (max-width: 460px) {
  .db-m {
    display: block;
  }
  .dib-m {
    display: inline-block;
  }
  .di-m {
    display: inline;
  }
  .dn-m {
    display: none;
  }
}

.pf {
  position: fixed
}

.v-center {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.h-center {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

.vh-100 {
  height: 100vh;
}

.mvh-100 {
  min-height: 100vh;
}

.full-page {
  width: 100%;
  height: 100vh;
}

.ta {
  &-l {
    text-align: left;
  }

  &-c {
    text-align: center;
  }

  &-r {
    text-align: right;
  }
}

.tt {
  &-up {
    text-transform: uppercase;
  }
}

@media screen and (min-width: 1px) and (max-width: 755px) {
  // column
  .col {
    padding-left: 12px;
    padding-right: 12px;

    &-m-80 {
      float: left;
      width: 80%;
    }

    &-m-90 {
      float: left;
      width: 90%;
    }
  }
}

@media screen and (min-width: 1px) and (max-width: 460px) {
  .ta-m {
    &-l {
      text-align: left;
    }

    &-c {
      text-align: center;
    }

    &-r {
      text-align: right;
    }
  }
}
