@import "../../assets/styles/styles.scss";

.img-gal {
  $a: &;
  position: relative;
  display: inline-block;
  margin: 8px;

  &-thumb {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center; /* You can adjust the position as needed */
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;

    &-wrapper {
      position: relative;
      width: 350px;
      height: 350px;
      display: inline-block;
      cursor: pointer;

      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba($black, 0.24);
        transition: all .3s ease;
        transition: background .3s ease;
      }

      &:hover {
        &:before {
          background: rgba($black, 0.5);
          transition: all .3s ease;
        }

        #{$a}-thumb-ovr {
          &-btn, &-desc {
            opacity: 1;
            top: 0;
          }
        }
      }

      @media screen and (min-width: 1px) and (max-width: 460px) {
        // background: rgba($black, 0.5);

        #{$a}-thumb-ovr {
          &-btn, &-desc {
            opacity: 1;
            top: 0;
          }
        }
      }

      &.selected {
        &:before {
          content: '';
          color: $white;
          background-color: rgba($green3, 0.35);
        }
        &:after {
          position: absolute;
          content: 'terpilih';
          color: $white;
          left: 0;
          right: 0;
          padding: 12px 0;
          text-align: center;
          letter-spacing: 1px;
          font-size: 16px;
          background-color: rgba($green3, .35);
        }

        #{$a}-thumb-ovr {
          &-btn, &-desc {
            opacity: 1;
            top: 0;
          }
        }
      }
    }

    &-ovr {
      position: absolute;
      top: 50%;
      left: 20px;
      right: 20px;
      transform: translateY(-50%);
      text-align: center;

      &-desc {
        color: $white;
        font-size: 24px;
        line-height: 32px;
        opacity: 0;
        top: 15px;
        transition: all .3s ease;

      }
      &-btn {
        position: relative;
        opacity: 0;
        top: 15px;
        margin-top: 12px;
        transition: all .3s ease;
        transition-delay: .2s;
      }
    }
  }

  @media screen and (min-width: 1px) and (max-width: 460px) {
    margin: 8px 0;
    display: block;
  }
}
