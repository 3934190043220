.a {
  &-h1 {
    font-size: 32px;
    line-height: 40px;
    // font-weight: bold;
    font-family: 'Roboto', sans-serif;
    // font-family: 'Lora', serif;

    b {
      font-weight: 700;
    }

    &.light {
      font-weight: 300;
    }
  }

  &-h2 {
    font-size: 24px;
    line-height: 36px;
    font-weight: bold;

    &.light {
      font-weight: 400;
    }

    b {
      text-decoration: underline;
    }
  }

  &-p {
    font-size: 16px;
    line-height: 24px;
  }
}


@media screen and (min-width: 1px) and (max-width: 460px) {
  .a {
    &-h1 {
      font-size: 20px;
      line-height: 32px;
    }

    &-h2 {
      font-size: 20px;
      line-height: 32px;

      // &-m-smaller {
      //   font-size: 16px;
      //   line-height: 26px;
      // }

      &.light {
        font-weight: 400;
      }
    }

    &-p {
      font-size: 14px;
      line-height: 20px;
    }
  }

}
